import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { catchError, firstValueFrom, map, tap, throwError } from 'rxjs';
import { RbacToken } from 'src/app/shared/interfaces/login/login.interface';
import { environment } from '../../../../environments/environment';
import { AuthService } from './auth.service';
import { GlobalLoaderService } from 'src/app/shared/components/global-loader/services/global-loader.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private _baseUrl = environment.rbacBaseUrl;
  private authService: AuthService;

  constructor(
    private http: HttpClient,
    private msalAuthService: MsalService,
    private injector: Injector,
    private loaderService: GlobalLoaderService
  ) {}

  public async refreshTokenIfExpired() {

    const rbacToken = localStorage.getItem('rbac-token') as string;

    const lastRefreshTs = Number(localStorage.getItem('last_refresh_ts') || 0);
    const now = new Date().getTime();

    if (rbacToken && (now - lastRefreshTs) > 45 * 60 * 1000) {
      this.loaderService.showLoader();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${rbacToken}`);

      return firstValueFrom(this.http.get<RbacToken>(`${this._baseUrl}/rbac/tokens/generate`, { headers }).pipe(
        map(response => response.accessToken),
        tap(accessToken => {
          localStorage.setItem('rbac-token', accessToken);
          localStorage.setItem('last_refresh_ts', new Date().valueOf().toString());
          const auth = this.authService = this.injector.get<AuthService>(AuthService);
          auth.getTokenPayload();
        }),
        catchError((error: HttpErrorResponse) => {
          this.msalAuthService.logout();
          localStorage.clear();
          console.error('Unauthorized access');
          this.loaderService.hideLoader();
          return throwError(() => error);
        })
      )).finally(() => {
        this.loaderService.hideLoader();
      });;
    }

    return '';
  }
}
