// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://cybersoc.api.qa.solvextk.com/api',
  rbacBaseUrl: 'https://cybersoc.rbac.qa.solvextk.com',
  domain: 'DOMAIN_URI',
  appVersion: 'v8.1.8',
  azure_config: {
    clientId: '7b5f1f75-2446-46e7-bb2e-42d1c8db7d0d',
    authority: 'https://login.microsoftonline.com/9acf6dd6-1978-4d9c-9a9c-c9be95245565',
    scope: 'api://6915a0af-3fc4-44a4-be62-411bd78958e6/Access_User',
    redirecturi: 'https://cybersoc.fe.qa.solvextk.com/auth/login',
  },
  logoutRedirectUri:  "https://cybersoc.fe.qa.solvextk.com/login"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
