import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable, Subject, takeUntil } from 'rxjs';

@Injectable()
export class CancelRequestInterceptor implements HttpInterceptor {

  private pendingRequestsMap = new Map<string, Subject<void>>();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const endpointUrl = req.url.split('?')[0];

    if (this.pendingRequestsMap.has(endpointUrl)) {
      const cancelSubject = this.pendingRequestsMap.get(endpointUrl);
      cancelSubject?.next();
      cancelSubject?.complete();
    }

    const cancelSubject = new Subject<void>();
    this.pendingRequestsMap.set(endpointUrl, cancelSubject);

    return next.handle(req).pipe(
      takeUntil(cancelSubject),
      finalize(() => {
        this.pendingRequestsMap.delete(endpointUrl);
      })
    );
  }
}
