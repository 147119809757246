import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { SearchPipePipe } from './Pipes/search-pipe.pipe';
import { FilterByKeyAndValuePipe } from './Pipes/filter-by-key-and-value-pipe.pipe';
import { FilterAgentsPipe } from './Pipes/filter-agents.pipe';
import { CapitalizeFirstLetterPipe } from './Pipes/capitalize-first-letter.pipe';
import { TimeAgoPipe } from './Pipes/time-ago.pipe';
import { GlowingTableComponent } from './skeletons/glowing-table/glowing-table.component';
import { TopAlertsGlowingComponent } from './skeletons/top-alerts-glowing/top-alerts-glowing.component';
import { ChartsGlowingComponent } from './skeletons/charts-glowing/charts-glowing.component';
import { CardsGlowingComponent } from './skeletons/cards-glowing/cards-glowing.component';
import { AlertDetailGlowingComponent } from './skeletons/alert-detail-glowing/alert-detail-glowing.component';
import { AlertDetailCommentsGlowingComponent } from './skeletons/alert-detail-comments-glowing/alert-detail-comments-glowing.component';
import { GlobalLoaderComponent } from './components/global-loader/global-loader.component';



@NgModule({
  declarations: [
    PaginationComponent, SearchPipePipe, FilterByKeyAndValuePipe,
    FilterAgentsPipe, CapitalizeFirstLetterPipe, TimeAgoPipe,
    GlowingTableComponent, TopAlertsGlowingComponent, ChartsGlowingComponent,
    CardsGlowingComponent, AlertDetailGlowingComponent, AlertDetailCommentsGlowingComponent, GlobalLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    PaginationComponent, SearchPipePipe, FilterByKeyAndValuePipe,
    FilterAgentsPipe, CapitalizeFirstLetterPipe, TimeAgoPipe,
    GlowingTableComponent, TopAlertsGlowingComponent, ChartsGlowingComponent,
    CardsGlowingComponent, AlertDetailGlowingComponent, AlertDetailCommentsGlowingComponent, GlobalLoaderComponent
  ],
})
export class SharedModule { }
