import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, catchError, filter, map, switchMap, tap, throwError } from 'rxjs';
import {
  DecryptedUser,
  RbacToken,
} from 'src/app/shared/interfaces/login/login.interface';
import { environment } from '../../../../environments/environment';
import { GlobalLoaderService } from 'src/app/shared/components/global-loader/services/global-loader.service';
import { Role } from 'src/app/shared/Enums/Roles.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user = new BehaviorSubject<DecryptedUser>(null as any);

  private _baseUrl = environment.rbacBaseUrl;
  // private userService = inject(UserService);

    constructor(private http: HttpClient, private msalService: MsalService, private loaderService: GlobalLoaderService) {
    this.getTokenPayload();
  }

  public async getTokenPayload() {
    const rbacToken = localStorage.getItem('rbac-token') as string;
    const msalToken = localStorage.getItem('msal_token');

    if (rbacToken) {
      const decodedRbacToken: any = jwtDecode(rbacToken);
      const userName = this.getUserNameFromMsalToken(msalToken);

      this.user.next({
        ...decodedRbacToken,
        name: userName,
      } as DecryptedUser);
    }
  }

  private getUserNameFromMsalToken(msalToken: string | null): string | null {
    if (msalToken) {
      const decodedMsalToken: any = jwtDecode(msalToken);
      return decodedMsalToken?.name || null;
    }
    return null;
  }

  public getRbacToken() {
    const msalToken = localStorage.getItem('msal_token');
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${msalToken}`
    );


    return this.http
    .get<RbacToken>(`${this._baseUrl}/Tokens/Generate`, { headers })
    .pipe(
      tap((response) => {
        localStorage.setItem('rbac-token', response.accessToken);
        localStorage.setItem(
          'last_refresh_ts',
          new Date().valueOf().toString()
        );
        this.getTokenPayload();
        this.loaderService.hideLoader();
      }),
      catchError((error) => {
        console.error('Error fetching RBAC token:', error);
        this.loaderService.hideLoader();
        return throwError(() => error);
      })
    );
  }

  public getUserPermissions(userId: string) {
    return this.http.get<string[]>(
      `${this._baseUrl}/roles/${userId}/permissions`
    );
  }

  public async isAllowed(routePermissions: string[] = []) {
    return routePermissions.every((scope) =>
      this.user.value.permissions.some((permission: string) =>
        permission.startsWith(scope)
      )
    );
  }


  public isAdmin(): boolean {
    const user = this.user?.value;
    return user?.Scopes.includes(Role.CBRT_ADMIN) || false;
  }

  public getCurrentUserDetails(): DecryptedUser {
    return this.user?.getValue();
  }

  public isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }
}

