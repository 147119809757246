import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { catchError, switchMap, throwError, of, from, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HelperService } from '../services/helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenPromise: Promise<string> | null = null;

  constructor(
    private msalAuthService: MsalService,
    private helperService: HelperService
  ) {}

  private getRbacToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      const maxAttempts = 5;
      const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

      const attemptGettingToken = async () => {
        const rbacToken = localStorage.getItem('rbac-token');
        if (rbacToken) {
          resolve(rbacToken);
        } else if (attempts < maxAttempts) {
          attempts++;
          await delay(500);
          attemptGettingToken();
        } else {
          reject('RBAC token is not available');
        }
      };

      attemptGettingToken();
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!request.url.includes(environment.baseUrl)) {
      return next.handle(request);
    }

    if (
      !request.url.includes(`${environment.baseUrl}/academic/auth`) &&
      !request.url.includes(`${environment.rbacBaseUrl}/Tokens/Generate`) &&
      (request.url.includes(`${environment.baseUrl}`) ||
        request.url.includes(`${environment.rbacBaseUrl}`))
    ) {
      return from(this.getRbacToken()).pipe(
        switchMap(rbacToken => {
          const authReq = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${rbacToken}`),
          });

          return next.handle(authReq).pipe(
            tap(() => {
              if (!this.refreshTokenPromise) {
                this.refreshTokenPromise = this.helperService
                  .refreshTokenIfExpired()
                  .then((token) => {
                    this.refreshTokenPromise = null;
                    return token;
                  });
              }
              return this.refreshTokenPromise;
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401 ) {
                this.msalAuthService.logout();
                localStorage.clear();
                console.error('Unauthorized access');
              }
              return throwError(() => error);
            })
          );
        }),
        catchError((error) => {
          console.log('error', error)
          return throwError(() => new Error(`Error: ${error}`,));
        })
      );
    }

    // Si no hay un token de acceso, pasar la solicitud sin modificar
    return next.handle(request);
  }
}
